/*------------------------
    Team Styles 
----------------------*/

.team {
    margin-top: 30px;
    overflow: hidden;
    border-radius: 6px;
    position: relative;
    .thumbnail {
        display: block;
        position: relative;
        z-index: 1;
        img {
            width: 100%;
            @extend %transition;
        }
        &::after {
            background: linear-gradient(to bottom, #fc004d 0, #000000 100%);
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            z-index: 1;
            opacity: 0;
            @extend %transition;

        }
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 35px 40px;
        z-index: 2;
        opacity: 0;
        @extend %transition;
        visibility: hidden;
        h4 {
            &.title {
                color: #ffffff;
                margin: 0;
                a {
                    color: #ffffff;
                }
            }
        }
        p {
            &.designation {
                color: #c6c9d8;
                font-size: 14px;
                line-height: 34px;
            }
        }
    }
    ul {
        &.social-icon {
            position: absolute;
            top: 25px;
            left: 35px;
            padding: 0;
            z-index: 2;
            list-style: none;
            display: flex;
            margin: 0 -10px;
            transform: translateY(-10px);
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            li {
                margin: 0 10px;
                a {
                    color: #ffffff;
                    font-size: 15px;
                    transition: 0.3s;
                    svg{
                        transition: 0.3s;
                    }
                    &:hover{
                        svg{
                            transform: scale(1.3);
                        }

                    }
                }
            }
        }
    }
    &:hover {
        .thumbnail {
            img {

            }
            &::after{
                opacity: 0.85;
                top: 0;
            }
        }
        ul {
            &.social-icon {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }
        }
        .content {
            opacity: 1;
            visibility: visible;
        }
    }
}
