.list-style--1{
  @extend %liststyle;

  li{
    color: #7e7e7e;
    font-weight: 400;
    font-size: 16px;
    line-height: inherit;
    margin-bottom: 10px;
    i,
    svg{
      color: $theme-color;
      margin-right: 5px;
    }
  }
  &.text-white{
    li{
      color: #ffffff;
    }
  }
}